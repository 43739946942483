import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App/App';
import {HashRouter} from 'react-router-dom';


ReactDOM.render(
    <App />
    , 
    
    document.getElementById('root'));

