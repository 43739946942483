import React from 'react';
import './Head.css'

class Head extends React.Component{
  render(){
    return (
      <>
          <section className='head'>           
            <div className='center'>
                <label>Insert text here</label>
            </div>
          </section>
      </>
    )
  }
}

export default Head;
